import * as React from "react"
import {Link} from "gatsby"
import Footer from "./home-footer"
import {StaticImage} from "gatsby-plugin-image";

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  const headerImage = <StaticImage src="../images/icon_and_title.png" alt="alt" />

  if (isRootPath) {
    header = (
      <h1 className="main-heading">
        <Link to="/">{headerImage}</Link>
      </h1>
    )
  } else {
    header = (
      <Link className="header-link-home" to="/">
        {headerImage}
      </Link>
    )
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
        <header className="global-header">{header}</header>
        <main>{children}</main>
        {Footer()}
    </div>
  )
}

export default Layout
